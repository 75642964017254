import React from 'react'
import styles from '@src/styles/style.module.scss'

interface IProps {
  Children: Array<any>
  title?: string
  bgColor?: string
}

const CompanySection: React.FC<IProps> = ({ Children, title, bgColor }) => {
  const styless = { backgroundColor: `${bgColor}` }

  return (
    <div className={styles.companySection} style={styless}>
      <h1 className={styles.companySection__title}>{title}</h1>
      <div className={`${styles.companyArea}`}>
        {Children.map((item: any) => {
          return (
            <div className={styles.companyGroup} style={item.title?{}:{justifyContent:'center'}}>
              <img className={`${styles.company__logo}`} src={item.logo} alt="" style={item.customImgStyle ?? {}} />
              {item.title && <h2 className={styles.company__title}>{item.title}</h2>}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CompanySection