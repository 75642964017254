import React from 'react'
import styles from '@src/styles/card.module.scss'

interface IProps {
  imageSrc: string
  title: string | undefined
  description: string | undefined
}

const Card: React.FC<IProps> = ({ title, description, imageSrc }) => {
  return (
    <div className={styles.card}>
      <img className={styles.card__img} src={imageSrc} alt={imageSrc.split('.')[0].toLowerCase()} />
      <h3 className={styles.card__title}>{title}</h3>
      <p className={styles.card__desc}>{description}</p>
    </div>
  )
}

export default Card
