import styles from '@src/styles/card.module.scss'
import React from 'react'
import Card from './Card'

interface IProps {
  title?: string | undefined
  titleClass?:string|undefined
  descriptionClass?:string|undefined
  description?: string | undefined
  child?: JSX.Element
}

const CardSection: React.FC<IProps> = ({ title, description, child,titleClass,descriptionClass }) => {
  return (
    <div className={`${styles.cardSection} grid `}>
      {title && <h1 className={`${styles.cardSection__title} ${titleClass??''}`} dangerouslySetInnerHTML={{ __html: (title!) }}/>}
      {description && <p className={`${styles.cardSection__desc} ${descriptionClass??''}`} dangerouslySetInnerHTML={{ __html: (description!) }}/>}
      {child??<></>}
    </div>
  )
}

export default CardSection
