import styles from '@src/styles/card.module.scss'
import React from 'react'

interface IProps {
  leftElement: JSX.Element
  rightElement: JSX.Element
  backgroundImage?: string | undefined
}

const HomeBanner: React.FC<IProps> = ({ leftElement, rightElement, backgroundImage }) => {
  return (
    <div className={`${styles.bannerSection}`} style={{ backgroundImage: `url('${backgroundImage}')` }}>
      <div className="flex self-center flex-col-reverse md:flex-row">
        <div className="flex flex-col self-center max-w-[80%] md:max-w-[50%] mb-[16px] md:pl-[10vw]">
          {leftElement}
        </div>
        <div className="md:pr-[10vw]">
          {rightElement}
        </div>
      </div>
    </div>
  )
}

export default HomeBanner
