import React, { useEffect, useLayoutEffect } from 'react'
import { NextPage } from 'next'
import styles from '@src/styles/test.module.scss'
import cardStyles from '@src/styles/card.module.scss'
import Head from 'next/head'
import userStyles from '@src/styles/style.module.scss'
import CardSection from '@src/components/CardSection'
import Card from '@src/components/Card'
import HomeBanner from '@src/components/HomeBanner'
import CompanySection from '@src/components/CompanySection'
import { CookiesModal } from '@src/pages/_app'
import useTranslation from '@src/hooks/translation'

const Home: NextPage = () => {
  const translator = useTranslation().home

  const cardInfos2 = [
    <Card
      imageSrc="card_img/animal_dog.png"
      title={translator.step2.card1.title}
      description={translator.step2.card1.text}
    />,
    <Card
      imageSrc="card_img/travel_insurance.png"
      title={translator.step2.card2.title}
      description={translator.step2.card2.text}
    />,
    <Card
      imageSrc="card_img/invoice_protection.png"
      title={translator.step2.card3.title}
      description={translator.step2.card3.text}
    />,
    <Card
      imageSrc="card_img/screen_breakage.png"
      title={translator.step2.card4.title}
      description={translator.step2.card4.text}
    />,
    <Card
      imageSrc="card_img/appliance_insurance.png"
      title={translator.step2.card5.title}
      description={translator.step2.card5.text}
    />,
    <Card
      imageSrc="card_img/emergency_health.png"
      title={translator.step2.card6.title}
      description={translator.step2.card6.text}
    />
  ]
  const cardInfos2_1=[
    <Card
      imageSrc="card_img/earthquake_insurance.svg"
      title={translator.step2.card7.title}
      description={translator.step2.card7.text}
    />,
    <Card
      imageSrc="card_img/traffic_insurance.svg"
      title={translator.step2.card8.title}
      description={translator.step2.card8.text}
    />,
  ]
  const cardInfos3 = [
    <Card
      imageSrc="card_img/embedded_insurance.png"
      title={translator.step3.card1.title}
      description={translator.step3.card1.text}
    />,
    <Card
      imageSrc="card_img/affinity_projeleri.png"
      title={translator.step3.card2.title}
      description={translator.step3.card2.text}
    />,
    <Card
      imageSrc="card_img/lead_uretimi.png"
      title={translator.step3.card3.title}
      description={translator.step3.card3.text}
    />,
  ]

  /* const partners1 = [
     {
       title: 'Teknoloji',
       logo: 'company_logos/paycell.svg'
     },

     {
       title: 'Akaryakıt',
       logo: 'company_logos/tp.svg'
     },

     {
       title: 'Enerji',
       logo: 'company_logos/oldubil.svg'
     }
   ] */
  const partners = [
    {
      title: translator.partner1,
      logo: 'company_logos/easycep.svg',
    },

    {
      title: translator.partner2,
      logo: 'company_logos/money.svg',
    },
    {
      title: translator.partner1,
      logo: 'company_logos/param.svg',
    },

    {
      title: translator.partner3,
      logo: 'company_logos/doggo.png',
    },
  ]

  const insuranceCompanies = [
    {
      logo: 'company_logos/turkiye_sigorta.svg',
    },
    {
      logo: 'company_logos/anadolu_sigorta.svg',
    },
    {
      logo: 'company_logos/axa_sigorta.svg',
    },
    {
      logo: 'company_logos/ak_sigorta.svg',
      customImgStyle:{height:'70px'}
    }, 
    {
      logo: 'company_logos/metlife_sigorta.png',
    },
    {
      logo: 'company_logos/gulf_sigorta.png',
    },
  ]

  useLayoutEffect(() => {
    setTimeout(() => {
      let isProduct = false
      const refs = window.location.href.split('?p=')
      if (refs !== null && refs.length > 1) {
        let targetto = refs[1]
        if (targetto === 'urun2') {
          targetto = 'urun'
          isProduct = true
        }
        const target = document.getElementById(targetto)

        console.log(targetto)

        let scrollToY = target!.offsetTop - 70
        if (targetto === 'ekibe-katil' || isProduct === true) {
          scrollToY = target!.offsetTop + 420
        }
        console.log(`Y: ${targetto} px: ${scrollToY}`)

        window.scrollTo(0, scrollToY)
        if (targetto === 'urun') {
          // @ts-ignore
          window.gtag('event', 'web_product_section_view')
        } else if (targetto === 'entegrasyon') {
          // @ts-ignore
          window.gtag('event', 'web_integration_section_view')
        } else if (targetto === 'partner') {
          // @ts-ignore
          window.gtag('event', 'web_partner_section_view')
        } else if (targetto === 'sigorta') {
          // @ts-ignore
          window.gtag('event', 'web_company_section_view')
        }
      } else {
        // @ts-ignore
        window.gtag('event', 'web_home_page_view')
      }
    }, 100)
  }, [])

  return (
    <>
      <div className={styles.homepage}>
        <Head>
          <title>{translator.pageTitle}</title>
        </Head>
        <HomeBanner
          leftElement={
            <>
              <div className={`${styles.bannerParagraphContainer}`}>
                <p className={cardStyles.bannerTitle}>{translator.preTitle}</p>
                <p className={cardStyles.bannerTitleBig}>{translator.titleColored}</p>
                <p className={cardStyles.bannerTitle}>{translator.postTitle}</p>
              </div>
              <p
                className={`${cardStyles.bannerDescription} pt-[24px]`}
                dangerouslySetInnerHTML={{ __html: translator.subTitle }}
              ></p>
              <div
                role="button"
                className={`${styles.coloredButton}`}
                onClick={() => {
                  if (typeof window !== undefined) {
                    // @ts-ignore
                    window.gtag('event', 'web_click_getstarted_button')
                    window.location.href = '/sigorta.html?p=bize-ulasin'
                  }
                }}
              >
                <span>{translator.startButton}</span>
              </div>
            </>
          }
          rightElement={
            <>
              <div className="xl:w-[521px] md:h-[371px]">
                <img className={`${cardStyles.bannerImage}`} src="/homeBanner.png" alt="sigorta hizmetleri" />
              </div>
            </>
          }
          backgroundImage="/homeBackground.png"
        />

        <div className={styles.homepageMainSpace}>
          <div className={`${userStyles.containerSpacing}`}>
            <CardSection
              title={translator.step0.title}
              titleClass={userStyles.bigTitle}
              description={translator.step0.text}
              descriptionClass={userStyles.bigDescription}
            />
          </div>
          <a id="ihtiyac" />
          <div className={`${userStyles.containerTopSpacing} bg-[#F5F5F5]`}>
            <CardSection
              title={translator.step1.title}
              description={translator.step1.text}
              child={<img className={`${styles.career__img} sm:max-w-[50%]`} src="/card_img/home_card1_image.png" />}
            />
          </div>

          <a id="urun" />
          <div className={`${userStyles.containerSpacing}`}>
            <CardSection
              title={translator.step2.title}
              description={translator.step2.text}
              child={
                <>
                <div className={`${cardStyles.cardSection__area}`}>
                  {cardInfos2?.map((item) => {
                    return item
                  })}
                </div>
                <div className={`${cardStyles.cardSection__area_2}`}>
                  {cardInfos2_1?.map((item) => {
                    return item
                  })}
                </div>
                  </>
              }
            />
          </div>
          <a id="entegrasyon" />
          <div className={`${userStyles.containerSpacing} bg-[#F5F5F5]`}>
            <CardSection
              title={translator.step3.title}
              description={translator.step3.text}
              child={
                <div className={`${cardStyles.cardSection__area}`}>
                  {cardInfos3?.map((item) => {
                    return item
                  })}
                </div>
              }
            />
          </div>
        </div>

        <a id="partner" />
        <CompanySection title={translator.partners} Children={partners} />
        <a id="sigorta" />
        <CompanySection
          bgColor="#F5F5F5"
          title={translator.agreedInsuranceCompanies}
          Children={insuranceCompanies} 
          />
        <CookiesModal />
      </div>
    </>
  )
}

export default Home
